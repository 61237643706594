import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import ImageComponent from '../GeneralComponents/ImageComponent'
import { clientBorderSVGbase64Url } from '../../utilities/Configurations'

const useStyles = makeStyles(theme => ({
    root: {
        background: `url(${clientBorderSVGbase64Url})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: "100% 100%",
        padding: "10px",
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: "center",
    },
    imageStyles: {
        width: 'auto',
        height: "100%",
        objectFit: 'contain',
        maxHeight: "120px",
        maxWidth: "200px"
    }
}))

export default function ClientsList({ clientList = [], clipList = true }) {
    const finalList = clipList ? clientList.slice(0, 9) : clientList
    const classes = useStyles()
    return (
        <div>
            <Grid container alignItems='stretch' spacing={3}>
                {
                    finalList.map(item => {
                        const { id, clientImage } = item
                        return (
                            <Grid item key={id} xs={12} sm={6} md={4} style={{ display: 'flex', minHeight: '145px' }}>
                                <div className={classes.root}>
                                    <ImageComponent
                                        src={!!clientImage ? clientImage.url : ''}
                                        alt={!!clientImage ? clientImage.alternativeText : ""}
                                        className={classes.imageStyles}
                                    />
                                </div>
                            </Grid>
                        )
                    })
                }
            </Grid>
        </div>
    )
}
