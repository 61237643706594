import React from 'react'
import { Grid, Typography, makeStyles, Box, Collapse } from '@material-ui/core'
import ImageComponent from '../../GeneralComponents/ImageComponent'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import IconButtonComponent from '../../GeneralComponents/IconButtonComponent'
import TagComponent from '../../GeneralComponents/TagComponent'
import LinkButton from '../../GeneralComponents/Buttons/LinkButton'

const useStyles = makeStyles(theme => ({
    container: {
        borderRadius: "24px",
        // textAlign: 'center',
        height: "100%",
        display: 'flex'
    },
    imageStyles: {
        borderRadius: '14px',
        width: "100px",
        height: "100px",
        objectFit: 'cover',
        // width: '100%',
        // height: "100%",
        // borderRadius: "12px 12px 0px 0px",
        // objectFit: 'cover',
        // [theme.breakpoints.up('md')]: {
        //     // height: "400px",
        //     maxHeight: "250px",
        // }
    },
    buttonStyles: {
        minWidth: "180px"
    },
    buttonContainer: {
        width: "100%"
    },
    textContainer: {
        padding: "20px",
        height: '100%'
    },
    description: {
        fontSize: "0.875rem",
        lineHeight: "1.5rem",
        color: '#526373'
        // [theme.breakpoints.up('sm')]: {
        //     fontSize: '1rem',
        //     lineHeight: "1.5rem"
        // }
    },
    descriptionBox: {
        fontSize: "0.875rem",
        lineHeight: "1.5rem",
        display: '-webkit-box',
        display: '-moz-box',
        display: 'box',
        color: theme.palette.text.primary,
        '-webkit-box-orient': 'vertical',
        '-moz-box-orient': 'vertical',
        "box-orient": 'vertical',
        [theme.breakpoints.up('sm')]: {
            fontSize: '1rem',
            lineHeight: "1.5rem"
        }
    },
    designation: {
        fontSize: "0.875rem",
        lineHeight: '1rem',
        fontWeight: '500'
        // color: "rgba(38, 59, 80, 0.9)",
        // [theme.breakpoints.up('sm')]: {
        //     fontSize: "0.75rem"
        // }
    },
    box: {
        height: '4rem'
    },
    textContainer: {
        maxWidth: "auto",
        [theme.breakpoints.up('sm')]: {
            maxWidth: '80%'
        }
    }
}))

function TeamCard({ data }) {
    const { memberName, designation, shortIntroduction, profilePicture, profileURL } = data
    const classes = useStyles()
    const descriptionRef = React.useRef(null)
    const [divHeight, setDivHeight] = React.useState(0)
    const [collapse, setCollapse] = React.useState(false)
    const profileText = !!profileURL ? profileURL.split("https://www.linkedin.com/in").pop().slice(0, -1) : ''
    React.useEffect(() => {
        if (descriptionRef.current) {
            setDivHeight(descriptionRef.current.offsetHeight)
        }
    }, [descriptionRef.current])

    React.useEffect(() => {
        window.addEventListener("resize", () => {

            if (descriptionRef.current) {
                console.log(descriptionRef.current.offsetHeight)
                setDivHeight(descriptionRef.current.offsetHeight)
            }
        })
        return () => {
            window.removeEventListener('resize', () => null)
        }
    }, [descriptionRef.current])
    return (
        <div >
            <Grid container spacing={2} >
                <Grid item xs={12}>
                    <Grid container alignItems='center' spacing={2} style={{ flexWrap: 'nowrap' }}>
                        <Grid item>
                            <ImageComponent
                                src={!!profilePicture ? profilePicture.url : ""}
                                alt={!!profilePicture ? profilePicture.alternativeText : ""}
                                className={classes.imageStyles}
                            />
                        </Grid>
                        <Grid item style={{ flex: 1 }}>
                            <Grid container className={classes.textContainer} spacing={1}>
                                <Grid item xs={12}>
                                    <Typography variant='h5' color='primary' style={{ lineHeight: "1.3rem" }} >{memberName}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant='body2' color='textPrimary' className={classes.designation} >{designation}</Typography>
                                </Grid>
                                {
                                    !!profileURL &&
                                    <Grid item xs={12}>
                                        <a href={profileURL} style={{ display: "flex", gap: "2px", alignItems: 'center', textDecoration: 'none' }} rel='no-follow' target='_blank' >
                                            <LinkedInIcon style={{ fontSize: '1.2rem' }} color='primary' />
                                            {
                                                !!profileText &&
                                                <Typography component='span' variant='body2' color='primary' className={classes.designation} >{profileText}</Typography>
                                            }
                                        </a>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='body1' className={classes.description}>{shortIntroduction}</Typography>
                </Grid>
            </Grid >
        </div >
    )
}


export default TeamCard


// <Grid item style={{ padding: "0px" }}>
// <ImageComponent
//     src={!!profilePicture ? profilePicture.url : ""}
//     alt={!!profilePicture ? profilePicture.alternativeText : ""}
//     className={classes.imageStyles}
// />
// </Grid>
// <Grid item style={{ display: 'flex', flex: 1 }} >
// <div style={{ paddingTop: "15px", flex: 1, display: 'flex' }}>
//     <Grid container direction='column' justifyContent='space-between'>
//         <Grid item>
//             <Grid container spacing={1}>
//                 <Grid item xs={12}>
//                     <Typography variant='h5' color='textPrimary' style={{ lineHeight: "1.3rem" }} >{memberName}</Typography>
//                 </Grid>
//                 <Grid item xs={12}>
//                     {/* <TagComponent spacing={0} array={[designation]} /> */}
//                     <Typography variant='body2' className={classes.designation} >{designation}</Typography>
//                 </Grid>
//                 <Grid item xs={12}>
//                     <Box
//                         overflow="hidden" textOverflow='ellipsis' style={{
//                             WebkitLineClamp: collapse ? 'unset' : 5,
//                             mozLineClamp: collapse ? 'unset' : 5,
//                             height: collapse ? '100%' : "7.5rem"
//                         }} className={classes.descriptionBox} >

//                         <div ref={descriptionRef}>
//                             <Typography variant='body1' className={classes.description} style={{ paddingTop: '5px' }} color='textPrimary'>{shortIntroduction}</Typography>
//                         </div>
//                         {/* {shortIntroduction} */}
//                     </Box>
//                     {/* <Collapse in={collapse} timeout={0} collapsedHeight={122}>
//                         <Box overflow="hidden" textOverflow='ellipsis' style={{ display: collapse ? 'none' : '-webkit-box' }} className={classes.descriptionBox} >
//                             {shortIntroduction}
//                         </Box>
//                         <div ref={descriptionRef} >
//                             <Typography variant='body1' className={classes.description} style={{ paddingTop: '5px' }} color='textPrimary'>{shortIntroduction}</Typography>
//                         </div>
//                     </Collapse> */}
//                 </Grid>
//                 {
//                     divHeight >= 120 &&
//                     <Grid item xs={12}>
//                         <LinkButton onClick={() => setCollapse(!collapse)}>
//                             {collapse ? 'Read Less' : "Read More"}
//                         </LinkButton>
//                     </Grid>
//                 }

//             </Grid>
//         </Grid>
//         {
//             !!profileURL &&
//             <Grid item>
//                 <IconButtonComponent href={profileURL}>
//                     <LinkedInIcon style={{ fontSize: '2rem' }} color='primary' />
//                 </IconButtonComponent>
//             </Grid>
//         }
//     </Grid>
// </div>

// </Grid >