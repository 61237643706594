import React from 'react'
import { Grid } from '@material-ui/core'
import TeamCard from './LocationCard'


export default function LocationCards({ cardList = [] }) {
    return (
        <div>
            <Grid container spacing={5} alignItems='stretch' justifyContent='center'>
                {
                    cardList.map((item) => {
                        return <Grid item key={item.id} style={{ display: 'flex' }} xs={12} md={4}>
                            <TeamCard data={item} />
                        </Grid>
                    })
                }
            </Grid>
        </div>
    )
}
